/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {DefaultLayout as MDXLayout} from "../components/DefaultLayout";
export {Head} from '../components/DefaultLayout';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h5: "h5",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {Block, Hero, HeroContent, TextGradient, TextSecondary, Spacer, CTAContactForm, HeroImage, FeatureGrid, TextPanel, TextPanelSide, TextPanelContent, TestimonialGrid, TestimonialGridItem} = _components;
  if (!Block) _missingMdxReference("Block", true);
  if (!CTAContactForm) _missingMdxReference("CTAContactForm", true);
  if (!FeatureGrid) _missingMdxReference("FeatureGrid", true);
  if (!Hero) _missingMdxReference("Hero", true);
  if (!HeroContent) _missingMdxReference("HeroContent", true);
  if (!HeroImage) _missingMdxReference("HeroImage", true);
  if (!Spacer) _missingMdxReference("Spacer", true);
  if (!TestimonialGrid) _missingMdxReference("TestimonialGrid", true);
  if (!TestimonialGridItem) _missingMdxReference("TestimonialGridItem", true);
  if (!TextGradient) _missingMdxReference("TextGradient", true);
  if (!TextPanel) _missingMdxReference("TextPanel", true);
  if (!TextPanelContent) _missingMdxReference("TextPanelContent", true);
  if (!TextPanelSide) _missingMdxReference("TextPanelSide", true);
  if (!TextSecondary) _missingMdxReference("TextSecondary", true);
  return React.createElement(React.Fragment, null, React.createElement(Block, {
    color: 'neutral'
  }, React.createElement(Hero, {
    variant: 'horizontal'
  }, React.createElement(HeroContent, null, React.createElement(_components.h1, null, "Using AI to ", React.createElement(TextGradient, null, "optimise inventory.")), React.createElement(_components.h5, null, "Hard earned profits are easily lost through poor inventory management."), React.createElement(TextSecondary, null, React.createElement(_components.p, null, "Profit Rocket consolidates multi-company sales and inventory data to generate daily alerts that are prioritised into actionable tasks in the hands of the right people at the right time.")), React.createElement(Spacer, {
    size: 4
  }), React.createElement(CTAContactForm, {
    buttonSize: 'x-large',
    buttonText: 'Get more info',
    defaultMessage: 'Tell me more about Profit Rocket',
    formTitle: 'Get more info',
    ButtonProps: {
      color: 'tertiary',
      variant: 'contained'
    }
  })), React.createElement(HeroImage, {
    src: 'https://images.unsplash.com/photo-1584960051731-cc8db9a869b9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
  }))), "\n", React.createElement(Block, {
    color: 'silver'
  }, React.createElement(FeatureGrid, {
    columns: 4,
    features: [{
      icon: 'auto_graph',
      title: 'Increased sales'
    }, {
      icon: 'show_chart',
      title: 'Improved stock availability'
    }, {
      icon: 'business',
      title: 'Improved promotion management'
    }, {
      icon: 'sell',
      title: 'Streamlined ranging'
    }, {
      icon: 'link_off',
      title: 'Improved phantom stock management'
    }, {
      icon: 'local_shipping',
      title: 'Fewer distribution gaps'
    }, {
      icon: 'stacked_bar_chart',
      title: 'Optimised order quantities'
    }, {
      icon: 'bolt',
      title: 'Real-time awareness'
    }]
  })), "\n", React.createElement(Block, {
    color: 'sky'
  }, React.createElement(TextPanel, null, React.createElement(TextPanelSide, null, React.createElement("img", {
    src: 'https://images.unsplash.com/photo-1565891741441-64926e441838?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1771&q=80',
    alt: 'Image by Markus Spiske',
    style: {
      borderRadius: 12,
      height: '100%',
      maxWidth: '100%',
      objectFit: 'cover'
    }
  })), React.createElement(TextPanelContent, null, React.createElement(_components.h2, null, "How it works"), React.createElement(_components.p, null, "Stock is your business’s capital so you have to make sure that it works hard. Having the right stock levels at the right place for the right price ensures that you get the best return. Minimising the stock outs is key for customer loyalty and minimising overstock levels ensures that you don’t have capital that underperforms."), React.createElement(_components.p, null, "Apple has a stock-turn of approximately 52, and its closest rival about 7. Never mind that Apple has great products, what it does really well is manage its stock levels, which in turn creates a supply level that maximises its profitability.\nProfit Rocket is a great tool to get you started on managing your stock levels optimally."))), React.createElement(Spacer, {
    size: 2
  }), React.createElement(FeatureGrid, {
    columns: 4,
    features: [{
      icon: 'camera_alt',
      title: 'Analyse',
      description: 'Sales and inventory data is gathered and correlated against your strategy and market insights.'
    }, {
      icon: 'watch',
      title: 'Prioritise',
      description: 'Alerts are ranked for impact.'
    }, {
      icon: 'shortcut',
      title: 'Mobilise',
      description: 'Alerts are generated at store, department or salesperson level'
    }, {
      icon: 'restart_alt',
      title: 'Iterate',
      description: 'Incremental improvements until efficiency is reached.'
    }]
  })), "\n", React.createElement(Block, null, React.createElement(_components.h2, null, "Why Profit Rocket?"), React.createElement(Spacer, {
    size: 4
  }), React.createElement(FeatureGrid, {
    columns: 2,
    features: [{
      icon: 'airline_stops',
      title: 'Increased stock turn',
      description: 'That is the number of times that your stock gets turned per year. The greater the stock turn the more efficiently you are matching supply and demand. Apple Corp are masters at this.'
    }, {
      icon: 'price_check',
      title: 'Reduced stock capital',
      description: 'Closely linked to stock turn is the value of capital tied up in stock. This affects the business in two ways, you can be overstocked or understocked. Overstock ties up capital and understock results in lost sales and the dilution of brand loyalty.'
    }, {
      icon: 'money_off_csr',
      title: 'Identifying lost sales',
      description: 'Quantifying the value of lost sales due to understock helps the business allocate limited resources to areas where the business can achieve the greatest returns.'
    }, {
      icon: 'crisis_alert',
      title: 'Target underperfoming products',
      description: 'Rationalising under-performing products is key but you need the right metrics to make the right decision.'
    }, {
      icon: 'loyalty',
      title: 'Monitor promotions',
      description: 'It is important that price driven promotions are monitored so as to ensure a healthy balance between market penetration without stock-outs.'
    }]
  })), "\n", React.createElement(Block, {
    color: 'silver'
  }, React.createElement(TestimonialGrid, {
    title: 'What our customers say'
  }, React.createElement(TestimonialGridItem, {
    author: 'Elanco',
    content: 'M2North are the epitome of where there is a will… there is a way.'
  }), React.createElement(TestimonialGridItem, {
    author: 'Retailer Brands, a division of Libstar Operations',
    content: 'M2North, thank you for your friendly, speedy, and prompt services.'
  }), React.createElement(TestimonialGridItem, {
    author: 'Glaxosmithkline Consumer Healthcare',
    content: 'M2North, agility is their second nature.'
  }), React.createElement(TestimonialGridItem, {
    author: 'Parrot Products',
    content: 'M2North are dependable, professional and an asset to any company.'
  }))), "\n", React.createElement(Block, {
    color: 'sky'
  }, React.createElement(TextPanel, null, React.createElement(TextPanelSide, null, React.createElement("img", {
    src: 'https://images.unsplash.com/photo-1525328437458-0c4d4db7cab4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    alt: 'Image by Markus Spiske',
    style: {
      borderRadius: 12,
      height: '100%',
      maxWidth: '100%',
      objectFit: 'cover'
    }
  })), React.createElement(TextPanelContent, null, React.createElement(_components.h2, null, "For Retailers"), React.createElement(_components.p, null, "Profit Rocket Retail let’s your suppliers know when things are going wrong by providing them with actionable alerts that have the biggest impact on your store operations – making sure you always have the right amount of stock to hand when you need it. Profit Rocket analyses your sales and inventory data for each of your suppliers, giving them high level insight into their performance - and helping them perform better. Plus they can unlock additional intelligence in your retail environment to see how they compare in their market space in turnover and profitability.")))), "\n", React.createElement(Block, {
    color: 'silver'
  }, React.createElement(TextPanel, null, React.createElement(TextPanelContent, null, React.createElement(_components.h2, null, "For Smaller Retail Suppliers"), React.createElement(_components.p, null, "Not all of your customers will collect detailed sales data, and some may not be able or willing to share this with you. Profit Rocket leverages your invoice data with your sales strategy to identify cross-sell and up-sell opportunities, and points out defecting customers before they leave.")), React.createElement(TextPanelSide, null, React.createElement("img", {
    src: 'https://images.unsplash.com/photo-1645967732161-db5c711e9e61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
    alt: 'Image by Markus Spiske',
    style: {
      borderRadius: 12,
      height: '100%',
      maxWidth: '100%',
      objectFit: 'cover'
    }
  })))), "\n", React.createElement(Block, {
    color: 'sky'
  }, React.createElement(TextPanel, null, React.createElement(TextPanelSide, null, React.createElement("img", {
    src: 'https://images.unsplash.com/photo-1627915589334-14a3c3e3a741?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1874&q=80',
    alt: 'Image by Markus Spiske',
    style: {
      borderRadius: 12,
      height: '100%',
      maxWidth: '100%',
      objectFit: 'cover'
    }
  })), React.createElement(TextPanelContent, null, React.createElement(_components.h2, null, "For Large Retail Suppliers"), React.createElement(_components.p, null, "Juggling production costs, logistics and stock holding across a multitude of retailers costs you time and money, and is a messy initiative. Profit Rocket Pro balances sales data with your business goals to push near real time alerts to your merchandising team on the ground, optimising ranging, distribution cost and stock availability.")))), "\n", React.createElement(Block, {
    color: 'silver'
  }, React.createElement(FeatureGrid, {
    columns: 3,
    features: [{
      icon: 'memory',
      title: 'Self-learning',
      description: 'Using machine-learning, we anticipate problems and calculate impact ahead of time.'
    }, {
      icon: 'currency_pound',
      title: 'Requires no additional overhead',
      description: 'Profit Rocket doesn’t require additional staff on the ground; your team will become more efficient. Profit Rocket finds it, you fix it!'
    }, {
      icon: 'smartphone',
      title: "There's an app!",
      description: 'Urgent and location-aware alerts are sent directly via the app, making teams more focused and productive.'
    }]
  })), "\n", React.createElement(Block, {
    color: 'sky'
  }, React.createElement("center", null, React.createElement(_components.h5, null, "Are you ready to take your business to the next level?"), React.createElement(Spacer, {
    size: 2
  }), React.createElement(CTAContactForm, {
    buttonSize: 'x-large',
    defaultMessage: 'Tell me more about Profit Rocket'
  }))));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
